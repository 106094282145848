export enum SiteMap {
  HOME = '/',
  NEWHOME = '/new-home',
  BRANDS = '/corners',
  EVENTS = '/events',
  FOOD = '/food',
  SERVICES = '/services',
  FIRST_SERVICE = '/services/parkovka-4623a1f5c2',
  CERTIFICATES = '/certificates',
  STORIES = '/news',
  CONTACTS = '/contacts',
  MAP = '/map',
  INFO = '/info',
  PRIVACY_POLICY = '/info/privacy-policy',
  FAQ = '/info/faq',
  INFO_PROGRAM_TERMS = '/info/program-terms',
  LOYALITY_OVERVIEW = '/loyality',
  ABOUT = '/about',
  LOGIN = '/login',
  SIGN_UP = '/signup',
  ACCOUNT = '/lk',
  LOYALITY = '/lk/loyality',
  PERSONAL_INFO = '/lk/personal-info',
  LK_PROGRAM_TERMS = '/lk/program-terms',
  FEEDBACK = '/lk/feedback',
  SUBSCRIPTIONS = '/lk/subscriptions',
  LOYALITY_BRANDS = '/lk/discounts',
  MY_EVENTS = '/lk/events',
  SEARCH = '/search',
  UNSORTED = '/unsorted',
  NOT_AVAILABLE = '/not-available'
}

export enum UrlQueryParam {
  SEARCH_QUERY = 'q',
  REDIRECT_URL = 'redirect_url',
}

export const FLOORS = Object.freeze([-1, 1, 2, 3, 4, 5, 6, 7]);

export enum LocalStorageItem {
  ATTEMPT_AUTH = 'attemptAuth',
  SEARCH_SUGGESTIONS = 'searchSuggestions',
  TOKEN = 'token',
  IS_REGISTERED = 'isRegistered',
  PHONE = 'phone',
  LANGUAGE = 'lang',
  DEVICE_ID = 'deviceId',
  BADGE_FIXED = 'bFixed',
  BADGE_VISIBLE = 'bVisisble',
}
